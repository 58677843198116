import React, { useRef, useState, useEffect, ChangeEvent } from "react";
import { Stack } from "@fluentui/react";
import {
  BroomRegular,
  DismissRegular,
  SquareRegular,
  ArrowUpload16Regular,
  ErrorCircleRegular,
  Warning16Filled
} from "@fluentui/react-icons";

import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";
import styles from "./Chat.module.css";
import Logo from "../../assets/flexW.png";
import { useOktaAuth } from "@okta/okta-react";
import Popup from "../../components/popup/popup";
import Loading from "../../components/happyloading/loadinghappy";
import {
  ChatMessage,
  Message,
  Conversation,
  ConversationRequest,
  conversationApi,
  Citation,
  ToolMessageContent,
  ChatResponse,
  saveLog,
  DALLE,
} from "../../api";
import { Answer } from "../../components/Answer";
import { QuestionInput } from "../../components/QuestionInput";
import { QuestionInputConversation } from "../../components/QuestionInput/QuestionInputConversation";

const Chat = () => {
  const lastQuestionRef = useRef<string>("");
  const chatMessageStreamEnd = useRef<HTMLDivElement | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [fileUploaded, setFileUploaded] = useState<boolean>(false);
  const [showLoadingMessage, setShowLoadingMessage] = useState<boolean>(false);
  const { authState, oktaAuth } = useOktaAuth();
  const [email, setEmail] = useState("");
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [showSidenav, setShowSidenav] = useState(false);
  const [showPopUp, setPopUp] = useState(false);
  const [setModel, setModelState] = useState("GPT-4o-mini");
  const models = ["GPT-4o-mini", "DALL-E"];
  const [conv, setConvNull] = useState(true);
  const [convAll, setConvNullAll] = useState(false);
  const [isCitationPanelOpen, setIsCitationPanelOpen] =
    useState<boolean>(false);
  const [isDalle, setDalle] = useState<boolean>(false);
  const [answers, setAnswers] = useState<ChatMessage[]>([]);
  const abortFuncs = useRef([] as AbortController[]);
  const dataRef = useRef<HTMLDivElement>(null);
  let MessageConversation =
    setModel != "DALL-E"
      ? "Type a new question..."
      : "Describe the image that you want...";
  let fileUpload = selectedFile?.name != null ? selectedFile.name : "No file";
  const [randomToken, setRandomToken] = useState("");
  const [conversations, setConversations] = useState<Conversation[]>([]);
  const [conversationsAll, setConversationsAll] = useState<Conversation[]>([]);
  const [loaded, setLoaded] = useState(false);
  const generateRandomToken = () => {
    const tokenLength = 10;
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let token = "";
    for (let i = 0; i < tokenLength; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      token += characters.charAt(randomIndex);
    }
    setRandomToken(token);
  };

  const toggleSidenav = () => {
    setShowSidenav(!showSidenav);
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    setFileUploaded(true);
    setSelectedFile(file || null);
  };

  // useEffect(() => {
  //   const fetchUserInfo = async () => {
  //     if (authState?.isAuthenticated) {
  //       try {
  //         const user = await oktaAuth.getUser();
  //         const accessToken = await oktaAuth.getAccessToken();
  //         user.email && setEmail(user.email);
  //         fetch(`/get_latest_conversations?user=${user.email}&n=4`,{headers:{Authorization: `Bearer ${accessToken}`}})
  //           .then((response) => response.json())
  //           .then((data: Conversation[]) => {
  //             setConversations(data);
  //             if (conversations.length == 0) {
  //               setConvNull(true);
  //             }
  //           })
  //           .catch((error) => {
  //             console.error("Error to obtain user", error);
  //           });
  //       } catch (error) {
  //         console.error("Error fetching user info:", error);
  //       }
  //     }
  //   };
  //   fetchUserInfo();
  // }, [authState?.isAuthenticated, oktaAuth]);

  const [activeCitation, setActiveCitation] =
    useState<
      [
        content: string,
        id: string,
        title: string,
        filepath: string,
        url: string,
        metadata: string
      ]
    >();

  useEffect(() => {
    const interval = window.setInterval(() => {
      if (dataRef.current) {
        dataRef.current.scrollTop = dataRef.current.scrollHeight;
      }
    }, 5000);
    generateRandomToken();
    return () => {
      clearInterval(interval);
    };
  }, []);

  const clearFileInput = () => {
    setSelectedFile(null);
    // Clear the input value (Note: This will not work for security reasons)
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const fileInputRef = React.createRef<HTMLInputElement>();

  const makeApiRequest = async (question: string) => {
    lastQuestionRef.current = question;
    setIsLoading(true);
    setShowLoadingMessage(true);
    const abortController = new AbortController();
    abortFuncs.current.unshift(abortController);
    const userMessage: ChatMessage = {
      role: "user",
      content: question,
    };
    const request: ConversationRequest = {
      messages: [
        ...answers.filter((answer) => answer.role !== "error"),
        userMessage,
      ],
      file: selectedFile,
    };

    let result = {} as ChatResponse;
    try {
      const accessToken = await oktaAuth.getAccessToken();
      let Dalle = "null";
      if (setModel == "DALL-E") {
        Dalle = await DALLE(question, accessToken);
        setDalle(true);
      }
      
      const response = await conversationApi(
        request,
        abortController.signal,
        Dalle,
        email,
        setModel,
        randomToken,
        question,
        accessToken
      );
      if (response?.body) {
        const reader = response.body.getReader();
        let runningText = "";
        while (true) {
          const { done, value } = await reader.read();
          if (done) break;

          var text = new TextDecoder("utf-8").decode(value);
          const objects = text.split("\n");
          objects.forEach((obj) => {
            try {
              runningText += obj;
              result = JSON.parse(runningText);
              setShowLoadingMessage(false);
              setAnswers([
                ...answers,
                userMessage,
                ...result.choices[0].messages,
              ]);
              runningText = "";
            } catch {}
          });
        }
        setAnswers([...answers, userMessage, ...result.choices[0].messages]);
        saveLog(
          email,
          userMessage.content,
          result.choices[0].messages[0].content,
          setModel,
          fileUpload,
          randomToken,
          accessToken
        );
      }
    } catch (e) {
      if (!abortController.signal.aborted) {
        console.error(result);
        let errorMessage =
          "An error occurred. Please try again. If the problem persists, please contact the site administrator.";
        if (result.error?.message) {
          errorMessage = result.error.message;
        } else if (typeof result.error === "string") {
          errorMessage = result.error;
        }
        setAnswers([
          ...answers,
          userMessage,
          {
            role: "error",
            content: errorMessage,
          },
        ]);
      } else {
        setAnswers([...answers, userMessage]);
      }
    } finally {
      setIsLoading(false);
      setShowLoadingMessage(false);
      setFileUploaded(false);
      abortFuncs.current = abortFuncs.current.filter(
        (a) => a !== abortController
      );
    }
    return abortController.abort();
  };

  const clearChat = () => {
    lastQuestionRef.current = "";
    setActiveCitation(undefined);
    answers.length = 0;
    setFileUploaded(false);
    clearFileInput();
    generateRandomToken();
  };

  const stopGenerating = () => {
    abortFuncs.current.forEach((a) => a.abort());
    setShowLoadingMessage(false);
    setIsLoading(false);
  };

  useEffect(
    () => chatMessageStreamEnd.current?.scrollIntoView({ behavior: "smooth" }),
    [showLoadingMessage]
  );

  const onShowCitation = (citation: Citation) => {
    setActiveCitation([
      citation.content,
      citation.id,
      citation.title ?? "",
      citation.filepath ?? "",
      "",
      "",
    ]);
    setIsCitationPanelOpen(true);
  };

  const parseCitationFromMessage = (message: ChatMessage) => {
    if (message.role === "tool") {
      try {
        const toolMessage = JSON.parse(message.content) as ToolMessageContent;
        return toolMessage.citations;
      } catch {
        return [];
      }
    }
    return [];
  };

  function sleep(milliseconds: number) {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
  }

  const addMessages = async (conversation: Message[], sessionid: string) => {
    await clearChat();
    await sleep(100);
    setPopUp(false);
    const newMessages: ChatMessage[] = conversation.map((message) => ({
      role: message.role,
      content: message.content,
    }));
    lastQuestionRef.current = newMessages[newMessages.length - 1].content;
    setAnswers([...answers, ...newMessages]);
    setRandomToken(sessionid);
    toggleSidenav();
  };

  const showAllConversations = async() => {
    setPopUp(true);
    if (!loaded) {
      conversationsAll.length = 0; 
      const accessToken = await oktaAuth.getAccessToken();
      fetch(`/get_latest_conversations?user=${email}&n=all`,{headers:{Authorization: `Bearer ${accessToken}`}})
        .then((response) => response.json())
        .then((data: Conversation[]) => {
          setConversations(data);
          setConversationsAll(data);
          if (conversations.length == 0) {
            setConvNullAll(true);
          }
        })
        .catch((error) => {
          console.error("Error to obtain user", error);
        });
      setLoaded(true);
    } else {
      console.log("loaded");
    }
  };

  const desactiveChat = async (sessionid: string) => {
    toggleSidenav();
    setPopUp(false);
    const accessToken = await oktaAuth.getAccessToken();
    await fetch(`/desactive_chats?session=${sessionid}`,{headers:{Authorization: `Bearer ${accessToken}`}})
      .then((response) => response.json())
      .catch((error) => {
        console.error("Error to update chat", error);
      });

    await fetch(`/get_latest_conversations?user=${email}&n=4`,{headers:{Authorization: `Bearer ${accessToken}`}})
      .then((response) => response.json())
      .then((data: Conversation[]) => {
        setConversations(data);
        if (conversations.length == 0) {
          setConvNull(true);
        }
      })
      .catch((error) => {
        console.error("Error to obtain user", error);
      });
    setLoaded(false);
    window.alert(`Session: ${sessionid} was deleted successfully.`);
  };

  return (
    <div className={styles.container} role="main">
      <Popup />
      {showSidenav == false ? (
        <div className={styles.toggleButton} onClick={toggleSidenav}>
          &#9776;
        </div>
      ) : (
        <></>
      )}

      <div className={`${styles.sidenav} ${showSidenav ? styles.open : ""}`}>
        <div className={styles.alignTextLeft} onClick={toggleSidenav}>
          x
        </div>
        <div>
          <h3 style={{ margin: "0 5px" }}>Select a GenAI </h3>
          <br />
          {models.map((elemento, index) => (
            <>
              <div key={index} className={styles.marginDiv}>
                <p>{elemento}</p>
                <div className={styles.toggleWrapper}>
                  <input
                    type="checkbox"
                    name={elemento}
                    className={styles.mobileToggle}
                    id={elemento}
                    checked={elemento === setModel}
                    onClick={() => setModelState(elemento)}
                  />
                  <label htmlFor={elemento}></label>
                </div>
              </div>
              <br />
            </>
          ))}
        </div>
        <div>
          <h4>Last 3 conversations:</h4>
          {conversations.length > 0 ? (
            <>
              {conversations.slice(0, 3).map((conversation, index) => (
                <div className={styles.card} key={index}>
                  <p className={styles.cookieHeading}>
                    {conversation.Conversation
                      ? conversation.Conversation.find(
                          (message) => message.role === "user"
                        )?.content.slice(0, 50)
                      : null}
                    ... <br />
                  </p>
                  <p className={styles.cookieDescription}>
                    From: {conversation.Date}
                  </p>

                  <div className={styles.buttonContainer}>
                    <button
                      className={styles.acceptButton}
                      onClick={() =>
                        addMessages(
                          conversation.Conversation,
                          conversation.Sessionid
                        )
                      }
                    >
                      Show
                    </button>
                    <button
                      className={styles.declineButton}
                      onClick={() => {
                        if (
                          window.confirm(
                            `Are you sure delete this chat: ${conversation.Sessionid}?`
                          )
                        ) {
                          desactiveChat(conversation.Sessionid);
                        }
                      }}
                    >
                      Delete
                    </button>
                  </div>
                </div>
              ))}
            </>
          ) : conv ? (
            <>
              <p>Under maintenance</p>
              <Warning16Filled/>
            </>
          ) : (
            <>
              <Loading />
            </>
          )}
          {conversations.length > 3 ? (
            <a className={styles.click} onClick={() => showAllConversations()}>
              Show all Conversations
            </a>
          ) : (
            <></>
          )}
          {showPopUp && (
            <div className={styles.popup}>
              <div className={styles.popupContent}>
                <div
                  className={styles.alignTextLeft}
                  onClick={() => setPopUp(false)}
                >
                  X
                </div>
                <h2>Show all conversations: </h2>
                <div className={styles.gridContainer}>
                  {conversationsAll.length > 3 ? (
                    conversationsAll.map((conversation, index) => (
                      <div className={styles.card} key={index}>
                        <p className={styles.cookieHeading}>
                          {conversation.Conversation
                            ? conversation.Conversation.find(
                                (message) => message.role === "user"
                              )?.content.slice(0, 50)
                            : null}
                          ... <br />
                        </p>
                        <p className={styles.cookieDescription}>
                          From: {conversation.Date}
                        </p>

                        <div className={styles.buttonContainer}>
                          <button
                            className={styles.acceptButton}
                            onClick={() =>
                              addMessages(
                                conversation.Conversation,
                                conversation.Sessionid
                              )
                            }
                          >
                            Show
                          </button>
                          <button
                            className={styles.declineButton}
                            onClick={() => {
                              if (
                                window.confirm(
                                  `Are you sure delete this chat: ${conversation.Sessionid}?`
                                )
                              ) {
                                desactiveChat(conversation.Sessionid);
                              }
                            }}
                          >
                            Delete
                          </button>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className={styles.Loading}>
                      <p>Loading...</p>
                      <Loading />
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      <Stack horizontal className={styles.chatRoot}>
        <div className={styles.chatContainer}>
          {!lastQuestionRef.current ? (
            <Stack className={styles.chatEmptyState}>
              <img src={Logo} className={styles.chatIcon} aria-hidden="true" />
              <h1 className={styles.chatEmptyStateTitle}>
                Welcome to Flex GenAI
              </h1>
              <h2 className={styles.chatEmptyStateSubtitle}>
                Feel free to ask me anything and I’ll do my best to provide you
                with a helpful response. Let’s get started!
              </h2>
              <QuestionInput
                clearOnSend
                placeholder={MessageConversation}
                disabled={isLoading}
                onSend={(question) => makeApiRequest(question)}
              />
              {fileUploaded || answers.length > 0 ? (
                <div
                  role="button"
                  tabIndex={0}
                  onClick={clearChat}
                  onKeyDown={(e) =>
                    e.key === "Enter" || e.key === " " ? clearChat() : null
                  }
                  aria-label="Clear session"
                >
                  <BroomRegular
                    className={styles.clearChatBroom}
                    style={{
                      background:
                        isLoading || answers.length === 0
                          ? "#BDBDBD"
                          : "radial-gradient(109.81% 107.82% at 100.1% 90.19%, #0F6CBD 33.63%, #2D87C3 70.31%, #8DDDD8 100%)",
                      cursor:
                        isLoading || answers.length === 0 ? "" : "pointer",
                    }}
                    aria-hidden="true"
                  />
                </div>
              ) : (
                <></>
              )}
              <div role="button" tabIndex={0} aria-label="Clear session">
                <ArrowUpload16Regular
                  className={styles.fileChatBroom}
                  aria-hidden="true"
                />
                <input
                  type="file"
                  accept=".pdf, .xlsx, .docx, .txt, .csv"
                  className={styles.inputFileHidden}
                  onChange={handleFileChange}
                />
              </div>
            </Stack>
          ) : (
            <div
              className={styles.chatMessageStream}
              style={{ marginBottom: isLoading ? "40px" : "0px" }}
              role="log"
            >
              <img
                src={Logo}
                className={styles.chatIconConversation}
                aria-hidden="true"
              />
              {answers.map((answer, index) => (
                <>
                  {answer.role === "user" ? (
                    <div className={styles.chatMessageUser} tabIndex={0}>
                      <div className={styles.chatMessageUserMessage}>
                        {answer.content}
                      </div>
                    </div>
                  ) : answer.role === "assistant" ? (
                    <div className={styles.chatMessageGpt}>
                      <Answer
                        answer={{
                          answer: answer.content,
                          citations: parseCitationFromMessage(
                            answers[index - 1]
                          ),
                        }}
                        onCitationClicked={(c) => onShowCitation(c)}
                        fileValue={selectedFile?.name ?? ""}
                        Dalle={isDalle}
                      />
                    </div>
                  ) : answer.role === "error" ? (
                    <div className={styles.chatMessageError}>
                      <Stack
                        horizontal
                        className={styles.chatMessageErrorContent}
                      >
                        <ErrorCircleRegular
                          className={styles.errorIcon}
                          style={{ color: "rgba(182, 52, 67, 1)" }}
                        />
                        <span>Error</span>
                      </Stack>
                      <span className={styles.chatMessageErrorContent}>
                        {answer.content}
                      </span>
                    </div>
                  ) : null}
                </>
              ))}
              {showLoadingMessage && (
                <>
                  <div className={styles.chatMessageUser}>
                    <div className={styles.chatMessageUserMessage}>
                      {lastQuestionRef.current}
                    </div>
                  </div>
                  <div className={styles.chatMessageGpt}>
                    <Answer
                      answer={{
                        answer: "Generating answer...",
                        citations: [],
                      }}
                      onCitationClicked={() => null}
                      fileValue={selectedFile?.name ?? null}
                    />
                  </div>
                </>
              )}
              <div ref={chatMessageStreamEnd} />
              <QuestionInputConversation
                clearOnSend
                placeholder={MessageConversation}
                disabled={isLoading}
                onSend={(question) => makeApiRequest(question)}
              />
              <div
                role="button"
                tabIndex={0}
                onClick={clearChat}
                onKeyDown={(e) =>
                  e.key === "Enter" || e.key === " " ? clearChat() : null
                }
                aria-label="Clear session"
              >
                <BroomRegular
                  title="Clean all conversation"
                  className={styles.clearChatBroomConversation}
                  style={{
                    background:
                      isLoading ||
                      (answers.length === 0 && fileUploaded === false)
                        ? "#BDBDBD"
                        : "radial-gradient(109.81% 107.82% at 100.1% 90.19%, #0F6CBD 33.63%, #2D87C3 70.31%, #8DDDD8 100%)",
                    cursor: isLoading || answers.length === 0 ? "" : "pointer",
                  }}
                  aria-hidden="true"
                />
              </div>
            </div>
          )}

          <Stack horizontal className={styles.chatInput}>
            {isLoading && (
              <Stack
                horizontal
                className={styles.stopGeneratingContainer}
                role="button"
                aria-label="Stop generating"
                tabIndex={0}
                onClick={stopGenerating}
                onKeyDown={(e) =>
                  e.key === "Enter" || e.key === " " ? stopGenerating() : null
                }
              >
                <SquareRegular
                  className={styles.stopGeneratingIcon}
                  aria-hidden="true"
                />
                <span className={styles.stopGeneratingText} aria-hidden="true">
                  Stop generating
                </span>
              </Stack>
            )}
            {selectedFile && fileUploaded && (
              <div
                role="button"
                className={
                  isLoading ? styles.fileUploadedDown : styles.fileUploaded
                }
                tabIndex={0}
              >
                <p style={{ cursor: "default", margin: "5px" }}>
                  {selectedFile.name} &nbsp;
                </p>
                <p
                  style={{ cursor: "pointer", margin: "5px" }}
                  onClick={clearFileInput}
                >
                  ✖
                </p>
              </div>
            )}
          </Stack>
        </div>
        {answers.length > 0 && isCitationPanelOpen && activeCitation && (
          <Stack.Item
            className={styles.citationPanel}
            tabIndex={0}
            role="tabpanel"
            aria-label="Citations Panel"
          >
            <Stack
              horizontal
              className={styles.citationPanelHeaderContainer}
              horizontalAlign="space-between"
              verticalAlign="center"
            >
              <span className={styles.citationPanelHeader}>Citations</span>
              <DismissRegular
                className={styles.citationPanelDismiss}
                onClick={() => setIsCitationPanelOpen(false)}
              />
            </Stack>
            <h5 className={styles.citationPanelTitle} tabIndex={0}>
              {activeCitation[2]}
            </h5>
            <div tabIndex={0}>
              <ReactMarkdown
                className={styles.citationPanelContent}
                children={activeCitation[0]}
                remarkPlugins={[remarkGfm]}
                rehypePlugins={[rehypeRaw]}
              />
            </div>
          </Stack.Item>
        )}
      </Stack>
    </div>
  );
};

export default Chat;
