import React from "react";
import ReactDOM from "react-dom/client";
import { HashRouter, Routes, Route } from "react-router-dom";
import { initializeIcons } from "@fluentui/react";

import { useNavigate } from "react-router-dom";
import { Security } from "@okta/okta-react";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import config from "./config";

import "./index.css";

import LoginCallback from "./components/okta/LoginCallback";
import Loading from "./components/okta/Loading";
import { RequiredAuth } from "./components/okta/SecureRoute";

import Layout from "./pages/layout/Layout";
import NoPage from "./pages/NoPage";
import Chat from "./pages/chat/Chat";

initializeIcons();

const oktaAuth = new OktaAuth({
    ...config.oidc,
    redirectUri: window.location.origin + "/"
});

function App() {
    const navigate = useNavigate();
    const restoreOriginalUri = (_oktaAuth: any, originalUri: string) => {
        let uri = "/";
        if (originalUri) {
            uri = originalUri;
        }
        navigate(toRelativeUrl(uri, window.location.origin));
    };
    return (
        <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
            <Routes>
                <Route path="/" element={<LoginCallback homePath="/chat" loadingElement={<Loading />} />} />
                <Route path="/chat" element={<Layout />}>
                    <Route path="" element={<Chat />} />
                    <Route path="*" element={<NoPage />} />
                </Route>
            </Routes>
        </Security>
            // <div className="container">
            //     <div className="centered-div">
            //         <h1>We'll be back soon!</h1>
            //         <p>We're very sorry for the inconvenience but we're performing maintenance.</p>
            //     </div>
            // </div>
        
    );
}

export default App;
